import React from "react";
import { useHorizontalScroll } from "../../lib/hooks/useHorizontalScroll";
import RoadmapItem from "./RoadmapItem";

const Roadmap = () => {
  const roadmap_items = [
    {
      roadmapTitle: "MID OF Q4 2024",
      title: "Planning",
      info: [
        "Definition of the business model, target audience",
        "Conducting market research, studying competitors",
      ],
    },
    {
      roadmapTitle: "Q1 2025",
      title: "Design",
      info: [
        "Development of the overall concept of the project, including functionality, design and user experience",
      ],
    },
    {
      roadmapTitle: "Q2 2025",
      title: "Prototype development",
      info: [
        "Development of a prototype of the service, including the main functions and interface",
      ],
    },
    {
      roadmapTitle: "MID OF Q2 2025",
      title: "Creating a Cryptotoken",
      info: [
        "Ethereum blockchain",
      ],
    },
    {
      roadmapTitle: "END OF Q2 2025",
      title: "Attracting investors",
      info: [
        "The increase in the number of the community in the social networks",
        "Selling tokens to investors",
      ],
    },
    {
      roadmapTitle: "Q3 2025",
      title: "Back-end Development",
      info: [
        "Set up the back-end infrastructure",
        "Implementation security measures (protecting user's data and transactions)",
        "Integratation blockchain networks",
      ],
    },
    {
      roadmapTitle: "Mid of Q3 2025",
      title: "iOS mobile app",
      info: [
        "Application development for iOS",
        "Unit testing",
        "UI testing",
      ],
    },
    {
      roadmapTitle: "Q4 2025",
      title: "Android mobile app",
      info: [
        "Application development for Android",
        "Unit testing",
        "UI testing",
      ],
    },
    {
      roadmapTitle: "End of Q4 2025",
      title: "Web App Development",
      info: [
        "Develop a web version of the service using modern web technologies",
        "Test and debug",
      ],
    },
    {
      roadmapTitle: "Start of Q1 2025",
      title: "Release in production",
      info: [
        "Placement of Android and iOS apps in stores",
        "Users' access to the web version on the internet",
      ],
    },
    {
      roadmapTitle: "Mid of Q1 2026",
      title: "Ongoing Maintenance and Updates",
      info: [
        "Improvements of security",
        "Regularly updated the website with bug fixes",
      ],
    },
  ];

  const scrollRef = useHorizontalScroll();

  return (
    <section id="roadmap" className="roadmap-area pt-130 pb-130">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">OUr Roadmap</span>
              <h2 className="title">
              Nelco Network Strategy and <br /> Project <span>Plan</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="bt-roadmap_x bt-roadmap-scrollbar"
              ref={scrollRef}
              style={{ overflow: "auto" }}
            >
              <div className="bt-roadmap-wrap">
                {roadmap_items.map((x, index) => (
                  <RoadmapItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
